import React from 'react';

export const ExtractDataFromProfile = (profile) => {
  const name = profile?.metadata?.profile_data?.name || profile?.independent_profile_data?.name || '';
  const gautra = profile?.metadata?.profile_data?.gautra || profile?.independent_profile_data?.gautra || '';
  const subcaste = profile?.metadata?.profile_data?.subcaste || profile?.independent_profile_data?.subcaste || '';
  const village = profile?.metadata?.profile_data?.village || profile?.independent_profile_data?.village || '';
  return { name, gautra, subcaste, village };
};

const FormatDisplayName = (name, gautra, subcaste = null) => {
  if (name && gautra && subcaste) {
    return `${name} ${subcaste} (${gautra})`;
  } else if (name && gautra) {
    return `${name} ${gautra}`;
  } else if (name) {
    return `${name}`;
  } else {
    return '';
  }
};

export function getLocationDisplay(location) {
  if (!location) {
    return 'Not Specified';
  }

  if (typeof location === 'string') {
    return location.trim() === '' ? 'Not Specified' : location;
  }

  if (typeof location === 'object' && Object.keys(location).length > 0) {
    return location.formatted_address || 'Not Specified';
  }

  return 'Not Specified';
}

export default FormatDisplayName;
